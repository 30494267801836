import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import ApexCharts from 'apexcharts';
import UtilsService, { TimeOut } from '@/services/utils/utils.service';
import ResourceChartsService, { MAIN_RESOURCES } from '@/services/resource-charts/resource-charts.service';
import FabButton from '@/components/fab-button/FabButton.vue';
import OrganizationsApi from '@/services/api/organizations.api';
import { getAppURIs } from '@/utils';
import OrganizationsList from '@/components/home/OrganizationsList.vue';
import OrganizationItem from '@/components/home/OrganizationItem.vue';
import { OrganizationsModule } from '@/store/modules/organizations';
let HomePage = class HomePage extends Vue {
    constructor() {
        super(...arguments);
        this.organizations = [];
        this.loading = false;
        this.pageLoading = false;
        this.orgData = { org_name: '', org_type: 'prod' };
        this.orgSelectOpts = [
            { value: 'prod', text: `Production`, selected: true },
            { value: 'sand', text: `Sandbox`, selected: false },
        ];
        this.dataTableFields = [
            { key: 'name', label: 'Org Name' },
            { key: 'backups', label: 'Total backups' },
            { key: 'records', label: 'Total records' },
            { key: 'size', label: 'Total size' },
            { key: 'backups_status', label: 'Backups by status' },
            { key: 'volume_records', label: 'Data volume (#records)' },
            { key: 'volume_size', label: 'Data volume (#size)' },
        ];
        this.resource = ResourceChartsService;
        this.utils = UtilsService;
    }
    get orgs() {
        return OrganizationsModule.organizations;
    }
    async mounted() {
        await this.renderCharts();
        await OrganizationsModule.fetchOrganizations();
    }
    onOrganizationsChanged(organizations) {
        if (organizations.length) {
            this.$nextTick(() => {
                this.fetchOrgs().then(() => this.fetchResource());
            });
        }
    }
    async renderCharts() {
        this.instanceResourceChart = new ApexCharts(document.querySelector('#disk_space_chart'), MAIN_RESOURCES.INSTANCE_RESOURCE);
        this.recordsVolChart = new ApexCharts(document.querySelector('#records_chart'), MAIN_RESOURCES.DATA_VOLUME.RECORDS);
        this.sizeVolChart = new ApexCharts(document.querySelector('#size_chart'), MAIN_RESOURCES.DATA_VOLUME.SIZE);
        await this.instanceResourceChart.render();
        await this.recordsVolChart.render();
        await this.sizeVolChart.render();
    }
    async fetchOrgs() {
        this.loading = true;
        try {
            const organizations = this.orgs;
            if (organizations.length) {
                this.organizations = organizations.map(org => ({
                    ...org,
                    recordsChart: {},
                    sizeChart: {},
                    jobsChart: {},
                }));
                await Promise.all(this.organizations.map(async (org) => {
                    const { size } = await this.resource.getOrgBackupsSize(`${org.id}`);
                    const { number: backups } = await this.resource.getOrgBackupsCount(`${org.id}`);
                    const { number: records } = await this.resource.getOrgRecordsCount(`${org.id}`);
                    org.size = this.utils.bytesToSizeString(size);
                    org.backups = `${backups}`;
                    org.records = `${records || 0}`;
                }));
            }
            this.loading = false;
            await TimeOut(300);
            if (this.organizations.length) {
                const mainCharts = {
                    records: [],
                    sizes: [],
                };
                await Promise.all(this.organizations.map(async (org) => {
                    const backups = await this.resource.getBackupsByStatus(`${org.id}`);
                    const records = await this.resource.getRecordsByDate(`${org.id}`);
                    const sizes = await this.resource.getBackSizeByDate(`${org.id}`);
                    const noEmptyCharts = {
                        jobs_chart: this.checkAllZeros(backups),
                        records_chart: this.checkAllZeros(records),
                        size_chart: this.checkAllZeros(sizes),
                    };
                    mainCharts.records.push({
                        org: org.name,
                        data: noEmptyCharts.records_chart ? records.map(j => ({ ...j })) : [],
                    });
                    mainCharts.sizes.push({ org: org.name, data: noEmptyCharts.size_chart ? sizes.map(j => ({ ...j })) : [] });
                    org.backupsStatusChart = noEmptyCharts.jobs_chart
                        ? new ApexCharts(document.querySelector(`#org_backups_status_chart_${org.id}`), {
                            ...MAIN_RESOURCES.ORGS_DATA.JOBS,
                            series: backups.map(j => j.number || 0),
                            colors: backups.map(j => this.utils.jobStatusColor(j.status)),
                            labels: backups.map(j => j.status || 'Unexpected'),
                        })
                        : undefined;
                    org.recordsChart = noEmptyCharts.records_chart
                        ? new ApexCharts(document.querySelector(`#org_records_chart_${org.id}`), {
                            ...MAIN_RESOURCES.ORGS_DATA.RECORDS,
                            series: [{ data: records.map(j => j.value || 0) }],
                        })
                        : undefined;
                    org.sizeChart = noEmptyCharts.size_chart
                        ? new ApexCharts(document.querySelector(`#org_size_chart_${org.id}`), {
                            ...MAIN_RESOURCES.ORGS_DATA.SIZE,
                            series: [{ data: sizes.map(j => j.value || 0) }],
                        })
                        : undefined;
                    await org.backupsStatusChart?.render();
                    await org.recordsChart?.render();
                    await org.sizeChart?.render();
                }));
                await this.recordsVolChart?.updateOptions({
                    ...MAIN_RESOURCES.DATA_VOLUME.RECORDS,
                    series: mainCharts.records.map(el => ({
                        name: el.org,
                        data: el.data.map(r => [new Date(r.date).getTime(), `${r.value || 0}`]),
                    })),
                    noData: {
                        text: 'No data to display.',
                        style: {
                            color: '#002d3f',
                        },
                    },
                }, false, true);
                await this.sizeVolChart?.updateOptions({
                    ...MAIN_RESOURCES.DATA_VOLUME.SIZE,
                    series: mainCharts.sizes.map(el => ({
                        name: el.org,
                        data: el.data.map(r => [new Date(r.date).getTime(), `${r.value || 0}`]),
                    })),
                    noData: {
                        text: 'No data to display.',
                        style: {
                            color: '#002d3f',
                        },
                    },
                }, false, true);
            }
        }
        catch (err) {
            this.loading = false;
            console.error('Error getting data: ', err);
        }
    }
    // eslint-disable-next-line class-methods-use-this
    checkAllZeros(data) {
        // eslint-disable-next-line no-nested-ternary
        return data.some(el => (el.value ? el.value > 0 : el.number ? el.number > 0 : null));
    }
    async fetchResource() {
        try {
            const { free, used } = await this.resource.getInstanceResources();
            this.instanceResourceChart?.destroy();
            this.instanceResourceChart = new ApexCharts(document.querySelector('#disk_space_chart'), {
                ...MAIN_RESOURCES.INSTANCE_RESOURCE,
                series: [free, used],
                labels: [`Free ${this.utils.bytesToSizeString(free)}`, `Used ${this.utils.bytesToSizeString(used)}`],
                tooltip: {
                    custom: (opts) => `<p style="margin:0.5rem 1rem">${opts.w.config.labels[opts.seriesIndex]}</p>`,
                    style: {
                        fontSize: '5px',
                    },
                },
            });
            await this.instanceResourceChart?.render();
            // eslint-disable-next-line no-empty
        }
        catch (err) { }
    }
    toOrgView(orgID, id) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { name } = this.organizations.find(org => org.id === id);
        localStorage.setItem('org_name', name);
        this.$router.push({ name: 'dashboard', params: { orgID: `${orgID}`, id: `${id}` } });
    }
    showConnectionModal() {
        this.$refs['connect-modal'].show();
    }
    hideModal() {
        this.$refs['connect-modal'].hide();
    }
    async runConnection() {
        if (!this.orgData.org_name.length) {
            return;
        }
        try {
            const { client } = getAppURIs();
            this.pageLoading = true;
            const { url } = await OrganizationsApi.login({
                type: this.orgData.org_type === 'prod' ? 'Production' : 'Sandbox',
                payload: {
                    name: this.orgData.org_name,
                    action: 'create',
                },
                redirectUri: `${client}/salesforce-callback`,
            });
            window.location.replace(url);
        }
        catch (error) {
            this.$toast.error(error);
        }
        finally {
            this.pageLoading = false;
        }
    }
};
__decorate([
    Watch('orgs', { deep: true })
], HomePage.prototype, "onOrganizationsChanged", null);
HomePage = __decorate([
    Component({
        components: { OrganizationItem, OrganizationsList, FabButton },
    })
], HomePage);
export default HomePage;
