import { __decorate } from "tslib";
import { Vue, Component, Emit } from 'vue-property-decorator';
let OrganizationsList = class OrganizationsList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    onAdd() { }
};
__decorate([
    Emit()
], OrganizationsList.prototype, "onAdd", null);
OrganizationsList = __decorate([
    Component({})
], OrganizationsList);
export default OrganizationsList;
