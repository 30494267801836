import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import OrganizationItemOptions from './OrganizationItemOptions.vue';
import { getAppURIs } from '@/utils';
import OrganizationsApi from '@/services/api/organizations.api';
import { OrganizationsModule } from '@/store/modules/organizations';
let OrganizationItem = class OrganizationItem extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    async openOrganization() {
        this.$router
            .push({
            name: 'dashboard',
            params: {
                id: this.id.toString(),
            },
        })
            .catch(() => false);
    }
    async deleteOrganization() {
        try {
            this.loading = true;
            if (await this.$confirm('Are you sure?')) {
                await OrganizationsModule.deleteOrganization(this.id);
                this.$toast.success('Organization was successfully deleted');
            }
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    async updateToken() {
        try {
            this.loading = true;
            const { client } = getAppURIs();
            const { url } = await OrganizationsApi.login({
                payload: {
                    id: this.id,
                    action: 'update',
                },
                redirectUri: `${client}/salesforce-callback`,
            });
            window.location.replace(url);
        }
        catch (error) {
            this.$toast.error(error);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop()
], OrganizationItem.prototype, "id", void 0);
__decorate([
    Prop()
], OrganizationItem.prototype, "name", void 0);
__decorate([
    Prop()
], OrganizationItem.prototype, "fullName", void 0);
__decorate([
    Prop()
], OrganizationItem.prototype, "salesforceId", void 0);
OrganizationItem = __decorate([
    Component({
        components: {
            OrganizationItemOptions,
        },
    })
], OrganizationItem);
export default OrganizationItem;
