import { __decorate } from "tslib";
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
let OrganizationItemOptions = class OrganizationItemOptions extends Vue {
    constructor() {
        super(...arguments);
        this.isMenuShown = false;
    }
    hideMenu() {
        this.isMenuShown = false;
    }
    onUpdate() {
        this.hideMenu();
    }
    onDelete() {
        this.hideMenu();
    }
};
__decorate([
    Prop()
], OrganizationItemOptions.prototype, "id", void 0);
__decorate([
    Emit()
], OrganizationItemOptions.prototype, "onUpdate", null);
__decorate([
    Emit()
], OrganizationItemOptions.prototype, "onDelete", null);
OrganizationItemOptions = __decorate([
    Component({})
], OrganizationItemOptions);
export default OrganizationItemOptions;
